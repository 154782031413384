import React, { useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "../sass/main.scss"
import { Layout, SEO, Scrollable, HeadSection, Image } from "../components"
import { Row, Col, Container } from "react-bootstrap"
import $ from "jquery"
import Slugify from "slugify"

const CustomAIPage = () => {
  useEffect(() => {
    $(".client").hover(
      function() {
        $(this).addClass("show")
      },
      function() {
        $(this).removeClass("show")
      },
    )
  })
  const data = useStaticQuery(graphql`
    query {
      clients: allClient(
        limit: 3
        filter: {
          client_category_id: { eq: 3 }
          show_in_category_page: { eq: 1 }
        }
      ) {
        edges {
          node {
            id
            title
            logo
            industry
            testimonial_commenter_position
            testimonial_commenter
            testimonial_comment
            featured_image
          }
        }
      }
    }
  `)
  const headTitle = (): string => {
    return "Custom AI"
  }
  const headSubTitle = (): string => {
    return "Custom-made enterprise AI solutions"
  }
  const headDescription = (): string => {
    return `We rapidly scope, prototype and productionise enterprise AI solutions. Most organisations have the same set of challenges. Improve efficiency. Reduce costs. Sell more. Price better. Stop churn. Retain talent.`
  }
  return (
    <Layout step={2}>
      <SEO
        title="Satalia Services | Bespoke Enterprise AI Systems"
        description="We rapidly scope, prototype and productionise enterprise AI solutions to radically improve operational efficiency."
        twitterTitle="Satalia Services | Bespoke Enterprise AI Systems"
        twitterDescription="We rapidly scope, prototype and productionise enterprise AI solutions to radically improve operational efficiency."
        openGraphTitle="Satalia Services | Bespoke Enterprise AI Systems"
        openGraphDescription="We scope and build enterprise AI systems."
      />
      <Container>
        <section id="custom-ai" className="mb-95">
          <HeadSection
            title={headTitle()}
            subtitle={headSubTitle()}
            description={headDescription()}
          ></HeadSection>
        </section>
        <section id="build-technology" className="mb-285">
          <Row className="justify-content-center">
            <Col xs="3" sm="8" md="10" lg="8" xl="8">
              <div className="st-block st-block--transparent">
                <h3>
                  We use machine learning and optimisation to build technologies{" "}
                  <span className="font-weight-medium">
                    that transform the organisations we work with.
                  </span>
                </h3>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="3" sm="4" md="5" lg="2" xl="2">
              <div className="st-block">
                <h3>Planning</h3>
                <ul>
                  <li className="text-inherit">Sales forecasting</li>
                  <li className="text-inherit">Predictive maintanence</li>
                  <li className="text-inherit">Demand forecasting</li>
                  <li className="text-inherit">Employee skills profiling</li>
                </ul>
              </div>
            </Col>
            <Col xs="3" sm="4" md="5" lg="2" xl="2">
              <div className="st-block">
                <h3>Logistics</h3>
                <ul className="text-inherit">
                  <li className="text-inherit">Warehouse optimisation </li>
                  <li className="text-inherit">Workforce optimisation </li>
                  <li className="text-inherit">Route optimisation </li>
                  <li className="text-inherit">Packaging optimisation</li>
                </ul>
              </div>
            </Col>
            <Col xs="3" sm="4" md="5" lg="2" xl="2">
              <div className="st-block">
                <h3>Service</h3>
                <ul className="text-inherit">
                  <li className="text-inherit">Stock replinishment </li>
                  <li className="text-inherit">Chat bots </li>
                  <li className="text-inherit">Call centre optimisation </li>
                  <li className="text-inherit">Fraud detection</li>
                </ul>
              </div>
            </Col>
            <Col xs="3" sm="4" md="5" lg="2" xl="2">
              <div className="st-block">
                <h3>Customer</h3>
                <ul className="text-inherit">
                  <li className="text-inherit">Sales driver analysis </li>
                  <li className="text-inherit">Customer churn analysis </li>
                  <li className="text-inherit">Price optimisation </li>
                  <li className="text-inherit">Customer segmentation</li>
                </ul>
              </div>
            </Col>
          </Row>
        </section>
        <section id="custom-developments" className="mb-285">
          <Row>
            <Col xs="3" sm="8" md="10" lg="8" xl="8">
              <div className="st-block st-block--transparent">
                <h3>
                  All of our custom developments are built upon a combination of
                  our core underlying technologies,{" "}
                  <span className="font-weight-medium">
                    backed by decades of academic research and tailored to suit
                    your exact needs.
                  </span>
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="3" sm="8" md="10" lg="12" xl="12">
              <Row>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium">Satalia SolveEngine</p>
                    <p>
                      We were the first company to provide
                      optimisation-as-a-service, powered by AI. We harness
                      thousands of academic algorithms to ensure the best
                      optimisation technologies are being used to solve
                      industries hardest problems.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium">Satalia RoutingEngine</p>
                    <p>
                      The Satalia Routing Engine is capable of generating
                      thousands of point-to-point routes in a fraction of a
                      second. It is a cornerstone of our logistics and workforce
                      products, which operate across a range of transport modes.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium">Satalia NetworkEngine</p>
                    <p>
                      The Satalia NetworkEngine can perform a variety of network
                      and people analysis to understand skills, behaviours and
                      relationships, grounded in organisational psychology
                      research.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium">Satalia TaskEngine</p>
                    <p>
                      All of Satalia's engines run on the cloud, and are
                      orchestrated by the Satalia TaskEngine. This ensures that
                      the right algorithms are being used to solve the right
                      problems with the optimal amount of resources.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium">Satalia DemandEngine</p>
                    <p>
                      Demand prediction is becoming more difficult in a rapidly
                      changing world. The Satalia DemandEngine uses a portfolio
                      of algorithms and data sources to predict future demand
                      from the past and present.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section id="engagement-process" className="mb-285">
          <Row className="justify-content-end">
            <Col
              xs="3"
              sm="8"
              md="10"
              lg="12"
              xl="9"
              className="offset-xl-3 offset-md-0"
            >
              <h2>Engagement process</h2>
            </Col>
            <Col xs="3" sm="4" md="5" lg="3" xl="3" className="mb-45">
              <div className="st-block">
                <p className="font-weight-medium">
                  01.
                  <br />
                  Explore
                </p>
                <p>
                  We work with you to understand your business and its
                  challenges. And then we explore, identify and prioritise the
                  solutions that would add the most value.
                </p>
              </div>
            </Col>
            <Col xs="3" sm="4" md="5" lg="3" xl="3" className="mb-45">
              <div className="st-block">
                <p className="font-weight-medium">
                  02.
                  <br />
                  Scope
                </p>
                <p>
                  We work with various stakeholders to understand the exact
                  requirements of the solution. We plan the proof of concept,
                  get to grips with your data, align on working methods and
                  propose a way forward.
                </p>
              </div>
            </Col>
            <Col xs="3" sm="4" md="5" lg="3" xl="3" className="mb-45">
              <div className="st-block">
                <p className="font-weight-medium">
                  03.
                  <br />
                  Prototype
                </p>
                <p>
                  We build a low-cost thinned down version of the solution to
                  prove future value and minimise upfront risk. We test against
                  predetermined metrics, make suitable changes and plan for the
                  next phase.
                </p>
              </div>
            </Col>
            <Col xs="3" sm="4" md="5" lg="3" xl="3" className="mb-45">
              <div className="st-block">
                <p className="font-weight-medium">
                  04.
                  <br />
                  Productionise
                </p>
                <p>
                  We build a robust, production-grade system and work with you
                  to deploy it across your business. We focus on measurement,
                  continuous improvement and employee training.
                </p>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
      <Container fluid>
        <section id="case-studies" className="pb-75">
          <Container className="p-0">
            <h2>Case studies</h2>
          </Container>
          <Scrollable
            itemSelector=".case-studies__item"
            section="#case-studies"
            leftIndex={0}
          >
            <Row className="case-studies__items st-scroll">
              <div className="scroll-padding"></div>
              {data.clients.edges.map((client, index) => (
                <div className="case-studies__item" key={index}>
                  <ClientItems client={client.node}></ClientItems>
                </div>
              ))}
              <div className="scroll-padding"></div>
            </Row>
          </Scrollable>
        </section>
      </Container>
    </Layout>
  )
}

const ClientItems = ({ client }) => {
  return (
    <div className="client">
      <div className="st-block">
        <Image
          path={client.featured_image}
          isSquare={true}
          alt={"Client | " + client.title}
        />
        <div className="client__content">
          <Row className="h-100">
            <Col xl="12" className="mt-auto">
              <span className="client__category text-white">
                {client.industry}
              </span>
              <h3 className="text-white">{client.title}</h3>
              <div className="client__comment">
                <p>{client.testimonial_comment}</p>
                <p className="font-weight-medium client__commenter">
                  {client.testimonial_commenter}
                </p>
                <Link
                  to={"/clients/" + Slugify(client.title.toLowerCase())}
                  className="learn-more"
                  title={client.title}
                >
                  Read more
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default CustomAIPage
